import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import ReactDOM from 'react-dom';
import MaterialTable from "material-table";
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import {baseUrl} from '../../config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {clickColor,inArray} from './../../library/util';
import Loader from "react-loader-spinner";
import axiosIntance,{fetchInstance,callAPI} from '../../helper/axios';
import FilePlaceholder from './../../assets/cloud-computing.png'
import downloadFilePlaceholder from './../../assets/download.png'
import FileIcon from './../../assets/google-docs.png'
import Message from './Message';
import AutomationSync from '../Common/AutomationSync';
import { tableInfoMessages } from '../Common/TableInfoMessage';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
function Shipstation() {
  const api_module='shipstation';
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

const handleTooltipOpen = () => {
    if (open == true) {
        setOpen(false);
    } else if (open == false) {
        setOpen(true);
    }
};
  const tableTitleInfoMes=tableInfoMessages[api_module]
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader,setLoader]=useState(false)
  const [resultCount,setResultCount]=useState(0);
  const [resultCountCounter,setResultCountCounter]=useState(0);
  checkUserTypeFn(history);
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});
  
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadBtn,setUploadBtn]=useState(true)
  let tableId='shipstation';
  const downloadFn = async e =>{
    let tableName=tableId;
    setLoader(true)
    window.location.href=baseUrl+`/export/${tableName}`;
    setLoader(false)
  }
  const onChange = e => {
    console.log('on changes fn*********');
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setUploadBtn(true)
    setSuccessMsg('');
    setMessage('');

  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploadBtn(false)
    
    try {
      const res = await axiosIntance.post(baseUrl+`/shipstation`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setLoader(true)
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 80) / progressEvent.total)));
          //setTimeout(() => setUploadPercentage(0), 10000);
        }
      });

      const { fileName, filePath } = res.data;
      if(res.data.success===true){
        setLoader(false)
        setSuccessMsg(res.data.message);
        setUploadPercentage(100)
        setFilename('')
      }else{
        setLoader(false)
        setMessage(res.data.message);
      }
      setUploadedFile({ fileName, filePath });
    } catch (err) {
      setLoader(false)
      console.log(err)
      setMessage('Something went wrong');
    }
  }
function checkUserTypeFn(history){
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const usertype=userdata.type;
  if(usertype!='admin' && usertype!='superadmin' && usertype!='subadmin' && usertype!='databasehelper' && usertype!='owneradmin'){
    history.push('/');
 }

}
  const handleSignOut = () => {
    window.localStorage.setItem('isLogin',false);
    history.push('/');
  }

  const [selectedrowList,setSelectedRowList]=useState([]);


 const setRowBackground = (selectedRow)=>{
        if(inArray(selectedRow.tableData.id,selectedrowList)==false){ 
        setSelectedRowList(oldArray => [...oldArray,selectedRow.tableData.id] );
        setSelectedRow(selectedRow.tableData.id)
        console.log(selectedrowList)
        }else{    
          setSelectedRow(500000)
          setSelectedRowList([])
        }
      }
      
  if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
    history.push('/');
  }

  const [shippingData,setShippingData]=useState([])
  
  const [message, setMessage] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  const columnsData=[
    { title: "Amount - Order Shipping", field: "amount_order_shipping" },
    { title: "Amount - Order Subtotal", field: "amount_order_subtotal" },
    { title: "Amount - Order Tax", field: "amount_order_tax" },
    { title: "Amount - Order Total", field: "amount_order_total" },
    { title: "Amount - Shipping Cost", field: "amount_shipping_cost" },

    { title: "Bill To - Account", field: "bill_to_account" },
    { title: "Carrier - Name", field: "carrier_name" },
    // { title: "Carrier - Carrier Selected", field: "carrier_carrier_selected" },
    // { title: "carrier - service requested", field: "carrier_carrier_selected" },
    { title: "Carrier - Service Selected", field: "carrier_service_selected" },
    { title: "Count - Number of Items", field: "count_number_of_items" },

    // { title: "Count - Number of Shipments", field: "count_number_of_shipments" },
    { title: "Custom - Field 1", field: "custom_field_1" },
    { title: "Custom - Field 2", field: "custom_field_2" },
    { title: "Custom - Field 3", field: "custom_field_3" },
    { title: "Customer - Email", field: "customer_email" },
    { title: "Date - Label Create Date", field: "date_label_create_date" },
    { title: "Date - Order Date", field: "date_order_date" },
    { title: "Date - Shipped Date", field: "date_shipped_date" },
    { title: "Delivery - Date", field: "delivery_date" },
    { title: "Delivery - Message", field: "delivery_message" },
    { title: "Gift - Flag", field: "gift_flag" },
    { title: "Gift - Message", field: "gift_message" },

    { title: "Insurance - Cost", field: "insurance_cost" },
    { title: "Insurance - Insured Value", field: "insurance_insured_value" },
    { title: "Insurance - Provider", field: "insurance_provider" },
    { title: "Market - Markeplace Name", field: "market_marketplace_name" },

    { title: "Market - Store Name", field: "market_store_name" },

    {title:"Operations - Batch ID",field:"operations_batch_id"},
    {title:"Operations - Batch Number",field:"operations_batch_number"},
    {title:"Operations - User Name",field:"operations_user_name"},
    {title:"Operations - Warehouse",field:"operations_warehouse"},
    {title:"Order - Number",field:"order_number"},
    {title:"Order - Source",field:"order_source"},
    {title:"Order - Status",field:"order_status"},
    {title:"Service - Package Type",field:"service_package_type"},

    { title: "Ship To - Address 1", field: "ship_to_address_1" },
    { title: "Ship To - Address 2", field: "ship_to_address_2" },
    { title: "Ship To - Address 3", field: "ship_to_address_3" },
    { title: "Ship To - City", field: "ship_to_city" },
    { title: "Ship To - Company", field: "ship_to_company" },
    { title: "Ship To - Country", field: "ship_to_country" },
    { title: "Ship To - Name", field: "ship_to_name" },
    { title: "Ship To - Phone", field: "ship_to_phone" },
    { title: "Ship To - Postal Code", field: "ship_to_postal_code" },
    { title: "Ship To - Residential Flag", field: "ship_to_residential_flag" },
    { title: "Ship To - State", field: "ship_to_state" },
    { title: "Ship To - Verified Flag", field: "ship_to_verified_flag" },

    {title:"Shipment - Ship Number",field:"shipment_ship_number"},
    {title:"Shipment - ID",field:"shipment_id"},
    {title:"Shipment - Carrier",field:"shipment_carrier"},
    {title:"Shipment - Declared Value",field:"shipment_declared_value"},
    {title:"Shipment - Height",field:"shipment_height"},
    {title:"Shipment - Length",field:"shipment_length"},
    {title:"Shipment - Package Count",field:"shipment_package_count"},
    {title: "Service - Package Type", field: "service_package_type" },
    {title:"Shipment - Tracking Number",field:"shipment_tracking_number"},
    {title:"Shipment - Weight",field:"shipment_weight"},
    {title:"Shipment - Weight (Oz)",field:"shipment_weight_oz"},
    {title:"Shipment - Width",field:"shipment_width"},
    
    // { title: "Customs - Package Contents", field: "customs_package_contents" },

    // { title: "Date - Hold Until", field: "date_hold_until" },

    // { title: "Date - Order Date", field: "date_order_date" },

    // { title: "Date - Shipped Date", field: "date_shipped_date" },

    // { title: "Dimensions - Height", field: "dimensions_height" },

    // { title: "Dimensions - Length", field: "dimensions_length" },

    // { title: "Dimensions - Width", field: "dimensions_width" },

    // { title: "Gift - Flag", field: "gift_flag" },

    // { title: "Gift - Message", field: "gift_message" },

    // { title: "Insurance - Cost", field: "insurance_cost" },

    // { title: "Insurance - Insured Value", field: "insurance_insured_value" },

    // { title: "Insurance - Provider", field: "insurance_provider" },

    // { title: "Market - Markeplace Name", field: "market_marketplace_name" },

    // { title: "Market - Market Order URL", field: "market_market_order_url" },

    // { title: "Market - Store Name", field: "market_store_name" },

    // { title: "Notes - From Buyer", field: "notes_from_buyer" },

    // { title: "Notes - Internal", field: "notes_internal" },

    // { title: "Notes - To Buyer", field: "notes_to_buyer" },

    // { title: "Order - CustomerID", field: "order_customer_id" },

    // { title: "Order - Number", field: "order_number" },

    // { title: "Order - Pay Method", field: "order_pay_method" },

    // { title: "Order - Status", field: "order_status" },

    // { title: "Service - Confirmation Type", field: "service_confirmation_type" },

    // { title: "Service - Package Type", field: "service_package_type" },

    // { title: "Ship To - Address 1", field: "ship_to_address_1" },
    // { title: "Ship To - Address 2", field: "ship_to_address_2" },
    // { title: "Ship To - Address 3", field: "ship_to_address_3" },
    // { title: "Ship To - City", field: "ship_to_city" },
    // { title: "Ship To - Company", field: "ship_to_company" },
    // { title: "Ship To - Country", field: "ship_to_country" },
    // { title: "Ship To - Name", field: "ship_to_name" },
    // { title: "Ship To - Phone", field: "ship_to_phone" },
    // { title: "Ship To - Postal Code", field: "ship_to_postal_code" },
    // { title: "Ship To - Residential Flag", field: "ship_to_residential_flag" },
    // { title: "Ship To - State", field: "ship_to_state" },
    // { title: "Ship To - Verified Flag", field: "ship_to_verified_flag" },

    // { title: "Ship To - Zone", field: "ship_to_zone" },
    // { title: "Source", field: "source" },
    // { title: "Tags", field: "tags" },
    // { title: "Weight", field: "weight" },
    // { title: "Weight - TotalOz", field: "weight_total_oz" },


  //  { title: "Carrier - Name", field: "carrier_name" },
  
  
  //  { title: "Date - Label Create Date", field: "date_label_create_date" },
  //  { title: "Date - Order Date", field: "date_order_date" },
  //  { title: "Date - Shipped Date", field: "date_shipped_date" },
  //  { title: "Delivery - Date", field: "delivery_date" },

  //  { title: "Delivery - Message", field: "delivery_message" },
  //  { title: "Order - Number", field: "order_number" },
  //  { title: "Order - Source", field: "order_source" },
  //  { title: "Order - Status", field: "order_status" },

 

   
 ]
const title="Shipments - Shipstation";
let tableOption={
  maxBodyHeight: '500px',
  headerStyle: { position: 'sticky', top: 0,fontSize:'17px',fontWeight:'bold' },
  rowStyle: rowData => ({
    backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
  })
  // headerStyle: { fontSize:'15px',fontWeight:'bold' },
  
}
const [successMsg,setSuccessMsg]=useState('')
  useEffect(() => {
    setLoader(true)
    loadData();
    return () => {
      console.log('un subscribe')
    }



  }, [])
 

  let loadData = async () => {
    try {
      const response = await callAPI('GET', `${api_module}/count`, {})
      setResultCount(response.data.result);
      console.log('resultCount='+resultCount)
      setResultCountCounter(1);
      setLoader(false)
    } catch (error) {
      setMessage('Something went wrong');
    }
  }

  let deleteDataFn = async () => {
    try {
      const response = await callAPI('DELETE', api_module, {})
      setSuccessMsg('Deleted!')
    } catch (e) {
      setMessage('Something went wrong');
    }
  }
  const SubmitDelete = (e) => {

    confirmAlert({
      title: '',
      message: 'Are you sure want to delete All data',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>deleteDataFn()
          

        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  }
 
  return (

    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />

      <div class="topbar">
<div className='successMsg viewScreen'>{successMsg}</div>
<div className='errorMsg viewScreen'>{message}</div>
<div className="overlay-content popup1 fileuploadcontainer">
<div className='fileupload_div'>

{message ? <Message msg={message} /> : null}
<form onSubmit={onSubmit}>
<div className="custom-file mb-4">

  <input
    type="file"
    className="custom-file-input"
    id="customFile"
    onChange={onChange}
    accept=".xls,.xlsx"
  />
  <label className='custom-file-label' htmlFor='customFile'>
    {filename ?
      <>
        <div className="placeholder margin-bottom">
          <img src={FileIcon} alt="file-placeholder" />
        </div>
        <label className="d-block">{filename}</label>
      </>
      :
      <>
        <div className="placeholder">
          <img src={FilePlaceholder} alt="file-placeholder" />
        </div>
        <label className="d-block">upload</label>
      </>
    }
  </label>
</div>
{
  filename ?
    <>
      {/* <Progress percentage={uploadPercentage} /> */}
      {loader && 
      <div className='uploadWatch'><Loader type="Watch" color="#2BAD60" height="50" width="50" /></div>
      }
      { uploadBtn && 
      <div className="text-center">
        <input
          type="submit"
          value="Upload"
          className="btn btn-primary margin-bottom mt-8"
        />
      </div>
      }
    </>
    :
    ""
}

</form>
{uploadedFile ? <div className="row mt-5">
<div className="col-md-6 m-auto"></div>
<h3 classNAme="text-center">{uploadedFile.fileName}</h3>
<img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
</div> : null}

</div>
{resultCount > 0 ? 
<div className='fileupload_div_right'>

{message ? <Message msg={message} /> : null}
<form onSubmit={onSubmit}>
<div className="custom-file mb-4">

  <input
    type="file"
    className="custom-file-input"
    id="customFile"
    onChange={onChange}
    accept=".xls,.xlsx"
  />
  <label className='custom-file-label' htmlFor='customFile'>
        <div className="placeholder">
          <img src={downloadFilePlaceholder} alt="file-placeholder" onClick={downloadFn} />
        </div>
        <label className="d-block">Export</label>
  </label>
</div>


</form>
{uploadedFile ? <div className="row mt-5">
<div className="col-md-6 m-auto"></div>
<h3 classNAme="text-center">{uploadedFile.fileName}</h3>
<img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
</div> : null}

</div>
:''}

</div>
<div className='delete-div'>
{
resultCount > 0 ? <button type="button" onClick={SubmitDelete} className="btn btn-primary">Delete All </button> : ''

}

</div>
<div className='uploadDownloadDivMidSec'><AutomationSync name={api_module}  /></div>
</div>
    <section id="content-wrapper">
      {loader &&
    <div className='loaderDiv'>  
        <Loader
              type="Circles"
              color="#b4d6fa"
              height={100}
              width={100}
              //timeout={1000} //3 secs
          />
    </div> 
    }
    {resultCountCounter>0 && 
        <MaterialTable
        // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        onRowClick={((evt, selectedRow) => 
          setRowBackground(selectedRow)
          )}																									

          columns={columnsData}
          data={query =>
            new Promise((resolve, reject) => {
              // console.log(query)
           
              let url = baseUrl+`/${api_module}?`
              url += `&_page=${query.page + 1}`
              url += `&_limit=${query.pageSize}`
              fetchInstance(url)
                .then(response => response.json())
                .then(result => {
                  resolve({
                    data: result.result,
                    page: query.page,
                    totalCount: resultCount,
                  })
                })
            })
          }
          options={tableOption}
          // title={title}

          title={
            <>
              <h3>{title}&nbsp;&nbsp;&nbsp;
                <Tooltip 
                PopperProps={{disablePortal: true}} 
                onClose={handleTooltipClose} 
                disableFocusListener 
                disableHoverListener 
                disableTouchListener                    
                open={open} 
                title={<h5 className="tooltipClass">{tableTitleInfoMes}</h5>}>
              <InfoIcon onClick={handleTooltipOpen} className="syncInfoIcon" />
              </Tooltip></h3>
              </>
              }
        />
}
      </section>
    </div>
    // <section className="bg-back">

    // </section>
  )
}

export default Shipstation