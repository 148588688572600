import React, { useState, useEffect, useRef } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import axiosIntance, { fetchInstance, callAPI } from '../../helper/axios';
import { Constants } from '../../helper/constant'
import Loader from "react-loader-spinner";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { syncMessage } from './syncMessage';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: 'white'
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        background: isFocused
            ? 'lightblue'
            : isSelected
                ? '#2a7bc0'
                : undefined,
        zIndex: 1
    }),
    menu: base => ({
        ...base,
        zIndex: 100
    })
}
const AutomationSyncWow = (props) => {
    let name = props.name; 
    let tooltipMessage = syncMessage[name];
    if(tooltipMessage=='' || tooltipMessage==undefined){
        tooltipMessage = syncMessage.all;
    }
    const [message, setMessage] = useState('')
    const [erroMessage, setErrorMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const selectInputRefO = useRef();
    const [syncType, setSyncType] = useState('add');
    const [open, setOpen] = React.useState(false);


    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        if (open == true) {
            setOpen(false);
        } else if (open == false) {
            setOpen(true);
        }
    };
    const handleChangeType = (target) => {
        if (target != undefined) {
            setSyncType(target.value);
        }
    }
    //const [syncType, setSyncType] = useState('');
    let tempCategory = [
        {
            "value": "add",
            "label": "Add Order"
        },
        {
            "value": "update",
            "label": "Update Order"
        }
    ];

    const loadFilterLabelType = (inputValue) => {
        
        return new Promise((resolve) => {
            resolve(tempCategory.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            ))
        });
    }
    const SyncAPI = async () => {
        let apiname = `${name}/job/sync`
        if (syncType == '' || syncType == undefined) {
            setErrorMessage('Please select Sync type')
            return false;
        }
        if (syncType == 'update') {
            apiname = `${name}/job/sync/update`
        }
        setLoader(true)
        setMessage(Constants.Message.apiSyncProgress);
        setErrorMessage('')
        const response = await callAPI('GET', apiname, {})
        setMessage(Constants.Message.apiSyncDone);
        setErrorMessage('')
        setLoader(false)
    }
    return (
        <div>
            <div className="api-sync-com-wow input-bar">
                <div className='syncSelectDiv input-bar-item'>
                    <AsyncSelect
                        ref={selectInputRefO}
                        components={animatedComponents}
                        onChange={e => handleChangeType(e)}
                        cacheOptions
                        placeholder="Sync Type"
                        defaultOptions
                        maxMenuHeight={250}
                        className="widthSelectInput async-select-sync"
                        classNamePrefix="select"
                        styles={colourStyles}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadFilterLabelType}
                        defaultValue={tempCategory[0]}
                    />
                </div>
                <div className='input-bar-item'>
                    <label className='custom-file-label'>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div className='syncInfoIconDivWow'>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={<h5 className="tooltipClass">{tooltipMessage}</h5>}
                                >
                                    <InfoIcon onClick={handleTooltipOpen} className="syncInfoIcon" />
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                        <div className="placeholder">
                            <RefreshIcon onClick={SyncAPI} className="refreshIcon" />
                        </div>
                        {/* <span>Sync</span> */}
                        {/* <label className="d-block">Sync</label> */}
                    </label>
                </div>
                
            </div>
            <div>
                    <div className='successMsg messageSyncComWow'>{message}</div>

                    {loader &&
                        <div className='uploadWatchSync wowSync'><Loader type="Watch" color="#2BAD60" height="30" width="30" />
                        </div>
                    }
                </div>
            <div className='errorMsg errorMsgSync'>{erroMessage}</div>
        </div>

    );
}

export default AutomationSyncWow