import React, { useState, useEffect } from 'react';
import axiosIntance, { fetchInstance, callAPI } from '../../helper/axios';
import { useHistory } from "react-router-dom";
import './customer.css';
import MaterialTable from "material-table";
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from '../../config';
import { clickColor, inArray } from './../../library/util';
import Loader from "react-loader-spinner";
import FilePlaceholder from './../../assets/cloud-computing.png'
import downloadFilePlaceholder from './../../assets/download.png'
import FileIcon from './../../assets/google-docs.png'
import Message from './Message';
import AutomationSync from '../Common/AutomationSync';
import { tableInfoMessages } from '../Common/TableInfoMessage';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

function Customer() {
  const api_module='customer';
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

const handleTooltipOpen = () => {
    if (open == true) {
        setOpen(false);
    } else if (open == false) {
        setOpen(true);
    }
};
  const tableTitleInfoMes=tableInfoMessages[api_module]
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false)
  checkUserTypeFn(history);
  const [selectedrowList, setSelectedRowList] = useState([]);
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});
  
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadBtn,setUploadBtn]=useState(true)
  
  let tableId='customer';
  const downloadFn = async e =>{
    let tableName=tableId;
    setLoader(true)
    window.location.href=baseUrl+`/export/${tableName}`;
    setLoader(false)
  }
  const onChange = e => {
    console.log('on changes fn*********');
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setUploadBtn(true)
    setSuccessMsg('');
    setMessage('');

  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploadBtn(false)
    
    try {
      const res = await axiosIntance.post(baseUrl+`/customer`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setLoader(true)
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 80) / progressEvent.total)));
          //setTimeout(() => setUploadPercentage(0), 10000);
        }
      });

      const { fileName, filePath } = res.data;
      if(res.data.success===true){
        setLoader(false)
        setSuccessMsg(res.data.message);
        setUploadPercentage(100)
        setFilename('')
      }else{
        setLoader(false)
        setMessage(res.data.message);
      }
      setUploadedFile({ fileName, filePath });
    } catch (err) {
      setLoader(false)
      console.log(err)
      setMessage('Something went wrong');
    }
  }


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;
    console.log('in check usertype fn');
    console.log(usertype)
    if (usertype != 'admin' && usertype != 'superadmin' && usertype != 'subadmin' && usertype != 'databasehelper' && usertype != 'owneradmin') {
      history.push('/');
    }

  }

  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  if (localStorage.getItem('isLogin') === "false" || localStorage.getItem('isLogin') == null) {
    history.push('/');
  }
  const [resultCount, setResultCount] = useState(0);
  const [resultCountCounter, setResultCountCounter] = useState(0);

  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  let columnsData = [
    { title: "Customer_id", field: "customer_id" },
    { title: "salutation", field: "salutation" },
    { title: "customer_number", field: "customer_number" },
    { title: "first_name", field: "first_name" },
    { title: "last_name", field: "last_name" },
    { title: "email", field: "email" },
    { title: "active", field: "active" },
    { title: "guest", field: "guest" },
    { title: "customer_group", field: "customer_group" },
    { title: "language", field: "language" },
    { title: "sales_channel", field: "sales_channel" },
    { title: "payment_method", field: "payment_method" },
    { title: "billing_id", field: "billing_id" },
    { title: "billing_salutation", field: "billing_salutation" },
    { title: "billing_title", field: "billing_title" },
    { title: "billing_first_name", field: "billing_first_name" },
    { title: "billing_last_name", field: "billing_last_name" },
    { title: "billing_company", field: "billing_company" },
    { title: "billing_street", field: "billing_street" },
    { title: "billing_zipcode", field: "billing_zipcode" },
    { title: "billing_city", field: "billing_city" },
    { title: "billing_country", field: "billing_country" },
    { title: "billing_phone_number", field: "billing_phone_number" },
    { title: "shipping_id", field: "shipping_id" },

    { title: "shipping_salutation", field: "shipping_salutation" },
    { title: "shipping_title", field: "shipping_title" },
    { title: "shipping_first_name", field: "shipping_first_name" },
    { title: "shipping_last_name", field: "shipping_last_name" },
    { title: "shipping_company", field: "shipping_company" },

    { title: "shipping_street", field: "shipping_street" },
    { title: "shipping_zipcode", field: "shipping_zipcode" },
    { title: "shipping_city", field: "shipping_city" },
    { title: "shipping_country", field: "shipping_country" },
    { title: "shipping_phone_number", field: "shipping_phone_number" }
  ];
  const title = 'Customers - Shopware';
  const tableOption = {
    sorting: false,
    search: false,
    loadingType: 'overlay',
    maxBodyHeight: '500px',
    headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
    rowStyle: rowData => ({
      backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
    })
  }



  useEffect(() => {
    setLoader(true)
    loadData();
    return () => {
      console.log('un subscribe')
    }



  }, [])

  let loadData = async () => {
    try {
      const response = await callAPI('GET', `${api_module}/count`, {})
      setResultCount(response.data.result);
      setResultCountCounter(1);
      setLoader(false)
    } catch (error) {
      setMessage('Something went wrong');
    }
  }

  let deleteDataFn = async () => {
    try {
      const response = await callAPI('DELETE', api_module, {})
      setSuccessMsg('Deleted!')
    } catch (e) {
      setMessage('Something went wrong');
    }
  }

  const SubmitDelete = (e) => {

    confirmAlert({
      title: '',
      message: 'Are you sure want to delete All data',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteDataFn()
        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  }
  return (
    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />


<div class="topbar">
<div className='successMsg viewScreen'>{successMsg}</div>
<div className='errorMsg viewScreen'>{message}</div>
<div className="overlay-content popup1 fileuploadcontainer">
<div className='fileupload_div'>

{message ? <Message msg={message} /> : null}
<form onSubmit={onSubmit}>
<div className="custom-file mb-4">

  <input
    type="file"
    className="custom-file-input"
    id="customFile"
    onChange={onChange}
    accept=".xls,.xlsx"
  />
  <label className='custom-file-label' htmlFor='customFile'>
    {filename ?
      <>
        <div className="placeholder margin-bottom">
          <img src={FileIcon} alt="file-placeholder" />
        </div>
        <label className="d-block">{filename}</label>
      </>
      :
      <>
        <div className="placeholder">
          <img src={FilePlaceholder} alt="file-placeholder" />
        </div>
        <label className="d-block">upload</label>
      </>
    }
  </label>
</div>
{
  filename ?
    <>
      {/* <Progress percentage={uploadPercentage} /> */}
      {loader && 
      <div className='uploadWatch'><Loader type="Watch" color="#2BAD60" height="50" width="50" /></div>
      }
      { uploadBtn && 
      <div className="text-center">
        <input
          type="submit"
          value="Upload"
          className="btn btn-primary margin-bottom mt-8"
        />
      </div>
      }
    </>
    :
    ""
}

</form>
{uploadedFile ? <div className="row mt-5">
<div className="col-md-6 m-auto"></div>
<h3 classNAme="text-center">{uploadedFile.fileName}</h3>
<img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
</div> : null}

</div>
{resultCount > 0 ? 
<div className='fileupload_div_right'>

{message ? <Message msg={message} /> : null}
<form onSubmit={onSubmit}>
<div className="custom-file mb-4">

  <input
    type="file"
    className="custom-file-input"
    id="customFile"
    onChange={onChange}
    accept=".xls,.xlsx"
  />
  <label className='custom-file-label' htmlFor='customFile'>
        <div className="placeholder">
          <img src={downloadFilePlaceholder} alt="file-placeholder" onClick={downloadFn} />
        </div>
        <label className="d-block">Export</label>
  </label>
</div>


</form>
{uploadedFile ? <div className="row mt-5">
<div className="col-md-6 m-auto"></div>
<h3 classNAme="text-center">{uploadedFile.fileName}</h3>
<img style={{ width: '100%' }} src={uploadedFile.filePath} alt="" />
</div> : null}

</div>
:''}

</div>
<div className='delete-div'>
{
resultCount > 0 ? <button type="button" onClick={SubmitDelete} className="btn btn-primary">Delete All </button> : ''

}

</div>
<div className='uploadDownloadDivMidSec'><AutomationSync name={api_module}  /></div>
</div>


      <section id="content-wrapper">

        {loader &&
          <div className='loaderDiv'>
            <Loader
              type="Circles"
              color="#b4d6fa"
              height={100}
              width={100}
            //timeout={1000} //3 secs
            />
          </div>
        }
        {resultCountCounter > 0 &&

          <MaterialTable
            // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}
            columns={columnsData}
            data={query =>
              new Promise((resolve, reject) => {
                let url = baseUrl + `/${api_module}?`
                url += `&_page=${query.page + 1}`
                url += `&_limit=${query.pageSize}`
                fetchInstance(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.result,
                      page: query.page,
                      totalCount: resultCount,
                    })
                  })
              })
            }

            options={tableOption}
            // title={title}
            title={
              <>
                <h3>{title}&nbsp;&nbsp;&nbsp;
                  <Tooltip 
                  PopperProps={{disablePortal: true}} 
                  onClose={handleTooltipClose} 
                  disableFocusListener 
                  disableHoverListener 
                  disableTouchListener                    
                  open={open} 
                  title={<h5 className="tooltipClass">{tableTitleInfoMes}
                  </h5>}>
                <InfoIcon onClick={handleTooltipOpen} className="syncInfoIcon" />
                </Tooltip></h3>
                </>
                }
          />
        }
      </section>
    </div>
  )
}

export default Customer
