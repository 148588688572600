import React, { useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import axiosIntance, { fetchInstance, callAPI } from '../../helper/axios';
import { Constants } from '../../helper/constant'
import Loader from "react-loader-spinner";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { syncMessage } from './syncMessage';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
const AutomationSync = (props) => {
    let name = props.name; console.log('name=',name)

    let tooltipMessage = syncMessage[name]

    if(tooltipMessage=='' || tooltipMessage==undefined){
        tooltipMessage = syncMessage.all;
    }

    

    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    // const handleTooltipOpen = () => {
    //     setOpen(true);
    // };

    const handleTooltipOpen = () => {
        if (open == true) {
            setOpen(false);
        } else if (open == false) {
            setOpen(true);
        }
    };
    const SyncAPI = async () => {
        console.log('name', name)
        setLoader(true)
        setMessage(Constants.Message.apiSyncProgress);
        const response = await callAPI('GET', `${name}/job/sync`, {})
        console.log('response', response.data.message)
        //setMessage(Constants.Message.apiSyncDone);
        setMessage(response.data.message);
        setLoader(false)
    }
    return (
        <div>
            <div className="api-sync-com">

                <label className='custom-file-label'>
                    <ClickAwayListener onClickAway={handleTooltipClose}>

                        <div className='syncInfoIconDiv'>




                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={<h5 className="tooltipClass">{tooltipMessage}</h5>}
                            >


                                <InfoIcon onClick={handleTooltipOpen} className="syncInfoIcon" />
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                    <div className="placeholder">
                        <RefreshIcon onClick={SyncAPI} className="refreshIcon" />
                    </div>

                    <label className="d-block">Sync</label>

                </label>
                <div className='successMsg messageSyncCom'>{message}</div>
                {loader &&
                    <div className='uploadWatchSync'><Loader type="Watch" color="#2BAD60" height="30" width="30" /></div>
                }
            </div>
        </div>
    );


}

export default AutomationSync