import React, { useState, useEffect } from 'react';
import axiosIntance, { fetchInstance, callAPI } from '../../helper/axios';
import { useHistory } from "react-router-dom";
import './index.css';
import MaterialTable from "material-table";
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from '../../config';
import { clickColor, inArray } from './../../library/util';
import Loader from "react-loader-spinner";
import {APIURLFn} from '../../helper/common';
import AutomationSync from '../Common/AutomationSync';
import { tableInfoMessages } from '../Common/TableInfoMessage';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
function Productmanfacture() {
  const api_module='productmanfacture';
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

const handleTooltipOpen = () => {
    if (open == true) {
        setOpen(false);
    } else if (open == false) {
        setOpen(true);
    }
};
  const tableTitleInfoMes=tableInfoMessages[api_module]
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false)
  checkUserTypeFn(history);
  const [selectedrowList, setSelectedRowList] = useState([]);


  const setRowBackground = (selectedRow) => {
    if (inArray(selectedRow.tableData.id, selectedrowList) == false) {
      setSelectedRowList(oldArray => [...oldArray, selectedRow.tableData.id]);
      setSelectedRow(selectedRow.tableData.id)
      console.log(selectedrowList)
    } else {
      setSelectedRow(500000)
      setSelectedRowList([])
    }
  }

  function checkUserTypeFn(history) {
    const userdata = JSON.parse(localStorage.getItem('userdata'));
    const usertype = userdata.type;
    console.log('in check usertype fn');
    console.log(usertype)
    if (usertype != 'admin' && usertype != 'superadmin' && usertype != 'subadmin' && usertype != 'databasehelper' && usertype != 'owneradmin') {
      history.push('/');
    }

  }

  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin', false);
    history.push('/');
  }

  if (localStorage.getItem('isLogin') === "false" || localStorage.getItem('isLogin') == null) {
    history.push('/');
  }
  const [resultCount, setResultCount] = useState(0);
  const [resultCountCounter, setResultCountCounter] = useState(0);

  const [message, setMessage] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const data = [['attribute', 'attribute2'], ['value1', 'value2']];
  let columnsData = [
    { title: "manufacturer_id", field: "manufacturer_id" },
    { title: "name", field: "name" },
    
   
  ];
  const title = 'Product Manufacturers - Shopware';
  const tableOption = {
    sorting: false,
    search: false,
    loadingType: 'overlay',
    maxBodyHeight: '500px',
    headerStyle: { position: 'sticky', top: 0, fontSize: '17px', fontWeight: 'bold' },
    rowStyle: rowData => ({
      backgroundColor: (selectedRow === rowData.tableData.id) ? clickColor : ''
    })
  }



  useEffect(() => {
    setLoader(true)
    loadData();
    return () => {
      console.log('un subscribe')
    }



  }, [])

  let loadData = async () => {
    try {
      const response = await callAPI('GET', `${api_module}/count`, {})
      setResultCount(response.data.result);
      setResultCountCounter(1);
      setLoader(false)
    } catch (error) {
      setMessage('Something went wrong');
    }
  }

  let deleteDataFn = async () => {
    try {
      const response = await callAPI('DELETE', api_module, {})
      setSuccessMsg('Deleted!')
    } catch (e) {
      setMessage('Something went wrong');
    }
  }

  const SubmitDelete = (e) => {

    confirmAlert({
      title: '',
      message: 'Are you sure want to delete All data',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteDataFn()
        },
        {
          label: 'No',
          onClick: () => console.log('No')
        }
      ]
    });
  }
  return (
    <div id="wrapper" className>
      <Sidebar />
      <Header handleSignOut={handleSignOut} />


      <div className='delete-div'>
        {
          resultCount > 0 ? <button type="button" onClick={SubmitDelete} className="btn btn-primary">Delete All </button> : ''

        }

      </div>
      <div className='errorMsg'>{message}</div>
      <div className='successMsg viewScreen'>{successMsg}</div>

      <div className='uploadDownloadDivMidSec'><AutomationSync name={api_module}  /></div>
      <section id="content-wrapper">

        {loader &&
          <div className='loaderDiv'>
            <Loader
              type="Circles"
              color="#b4d6fa"
              height={100}
              width={100}
            //timeout={1000} //3 secs
            />
          </div>
        }
        {resultCountCounter > 0 &&

          <MaterialTable
            // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

            onRowClick={((evt, selectedRow) =>
              setRowBackground(selectedRow)
            )}
            columns={columnsData}
            data={query =>
              new Promise((resolve, reject) => {
                let url = baseUrl + `/${api_module}?`
                url += `&_page=${query.page + 1}`
                url += `&_limit=${query.pageSize}`
                fetchInstance(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.result,
                      page: query.page,
                      totalCount: resultCount,
                    })
                  })
              })
            }

            options={tableOption}
            // title={title}

            title={
              <>
                <h3>{title}&nbsp;&nbsp;&nbsp;
                  <Tooltip 
                  PopperProps={{disablePortal: true}} 
                  onClose={handleTooltipClose} 
                  disableFocusListener 
                  disableHoverListener 
                  disableTouchListener                    
                  open={open} 
                  title={<h5 className="tooltipClass">{tableTitleInfoMes}
                  </h5>}>
                <InfoIcon onClick={handleTooltipOpen} className="syncInfoIcon" />
                </Tooltip></h3>
                </>
                }
          />
        }
      </section>
    </div>
  )
}

export default Productmanfacture
